//setting
@import "../common/css/_mq.scss";
@import "../common/css/_var.scss";
@import "../common/css/_mixin.scss";

.top {
  .header {
    border-bottom: 0px solid #000;
  }

  .notice {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    right: 0;
    margin: 0 auto;
    background: #fff;
    padding: 25px;
    z-index: 999;
    display: block;

    @include tab {
      padding: 20px 3%;
    }

    @include sp {
      padding: 20px 5.3%;
    }

    .container {
      @include flex;
      @include alignItems(center);
      @include justifyContent(center);
      max-width: 900px;
      margin: 0 auto;

      @include tab {
        @include flexDirection(col);
        @include alignItems(start);
      }

      @include sp {
        @include flexDirection(col);
        @include alignItems(start);
      }
    }

    .noticeTitle {
      margin-right: 60px;
      color: #e6002d;
      font-size: 1.4rem;
      font-weight: 700;

      @include tab {
        margin-right: 0;
        margin-bottom: 10px;
      }

      @include sp {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    .noticeTextWrap {
      font-size: 1.3rem;
      margin-bottom: 0;
      width: 80%;

      @include sp {
        font-size: 1.2rem;
      }

      .ttl {
        font-weight: 700;
        margin-bottom: 10px;
        display: block;

        @include sp {
          margin-bottom: 5px;
        }
      }
    }

    .noticeClose {
      width: 24px;
      position: absolute;
      right: 100px;
      top: 30px;
      cursor: pointer;

      @include tab {
        right: 4%;
        top: 20px;
      }

      @include sp {
        right: 6%;
        top: 20px;
      }

      img {
        width: 100%;
      }
    }

    &.hide {
      display: none;
    }
  }

  .mainContents {
    margin-top: -90px;

    .slider {
      .slick-slide {
        overflow: hidden;
        width: 100%;
        @include sp {
          position: relative;
          &:before {
            content: '';
            display: block;
            padding-top: calc(855 / 750 * 100%);
          }
        }
      }
    }

    .bannerSlider {
      .img {
        width: 100%;
        @include pc{
          height: 800px;
          overflow: hidden;
          display: block;
        }
        @include sp {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
        }

        img {
          min-width: 100%;
          width: auto;
          height: 100%;
          min-height: 100%;
          max-height: 800px;
          object-fit: cover;
          object-position: 50% 50%;
          @include sp {
            max-height: none;
          }
        }

        .pcOnly{
          @include sp{
            display: none; 
          }
        }

        .spOnly{
          @include pc{
            display: none;
          }
        }

      }
    }

    .tabOnly {
      display: none;

      @include tab {
        display: block;
      }
    }

    .spOnly {
      @include sp {
        display: block;
      }
    }

    .container {
      max-width: 1200px;
      margin: 0 auto;

      @include tab {
        padding: 0 3%;
      }

      @include sp {
        padding: 0 5.3%;
      }
    }

    .bTitle {
      margin-bottom: 60px;

      @include sp {
        margin-bottom: 40px;
      }

      span {
        margin-top: 24px;
        font-size: 2.4rem;
        font-family: $font-zenmaru;
        color: #323232;
        font-weight: 700;
        display: block;
        letter-spacing: 0.05em;

        @include sp {
          margin-top: 18px;
        }
      }

      .bTitleTextImage {
        height: 85px;
        object-fit: contain;

        @include sp {
          height: 53px;
        }
      }
    }

    .lgBtn a {
      width: 400px;
      padding: 22px;

      @include sp {
        width: 100%;
        padding: 18px;
      }
    }

    .smlBtn {
      margin-top: 20px;
    }

    .banner {
      position: relative;

      &::before {
        content: '';
        background: url('/images/bnr_decor_03.png') no-repeat;
        width: 36vw;
        height: 11vw;
        background-size: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        @include large{
          width: 32vw;
        }
        @include tab {
          width: 500px;
          height: 140px;
          left: -130px;
        }
      }

      &::after {
        content: '';
        background: url('/images/bnr_decor_04.png') no-repeat;
        width: 34vw;
        height: 10vw;
        background-size: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        // @include tab {
        //   width: 600px;
        //   height: 148px;
        //   right: -265px;
        // }
      }

      .bannerDecor,
      .bannerDecorGreen {
        position: absolute;
        bottom: -2px;
        left: 0;
        z-index: 2;
        width: 36%;
        @media  screen and (min-width: 750px) and (max-width: 1500px) { 
          width: 540px;
        }
        img {
          width: 100%;
        }
      }

      .bannerDecorGreen {
        z-index: 1;
        bottom: 0;
        width: 21%;
        @media  screen and (min-width: 750px) and (max-width: 1500px) { 
          width: 315px;
        }
      }

      .bannerText {
        img {
          position: absolute;
          right: calc(60 / 1400 * 100%);
          bottom: calc(125 / 930 * 100%);

          @media  screen and (min-width: 750px) and (max-width: 1200px) { 
            width: 53.9%;
            top: 54%;
          }
          
          @include sp {
            top: 0;
            bottom: 0px;
            right: 0;
            left: 0;
            margin: auto;
            width: 95%;
          }
        }
        .pcOnly{
          @include sp{
            display: none; 
          }
        }

        .spOnly{
          @include pc{
            display: none;
          }
        }
      }

      .sliderNav {
        z-index: 3;
        position: absolute;
        margin-top: 0;
        left: 11%;
        bottom: 15px;

        @include tab {
          left: 3%;
          bottom: -10px;
        }

        @include sp {
          left: 0;
          bottom: -100px;
          right: 0;
          margin: 0 auto;
        }
      }

      .slider .slick-arrow {
        width: 40px;
        height: 40px;
        min-width: 40px;

        &.slick-next {
          margin-left: 15px;
        }

        &.slick-prev {
          margin-right: 15px;
        }
      }
    }

    .values {
      position: relative;
      padding: 120px 0 150px;

      @include tab {
        padding: 120px 0 0;
      }

      @include sp {
        padding: 160px 0 0;
      }

      &::before {
        content: '';
        background: url('/images/img_values_decor.png') no-repeat;
        width: 44.6vw;
        height: 10.5vw;
        background-size: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        @media  screen and (min-width: 750px) and (max-width: 1000px) { 
          width:calc(100vw - 555px) ;
        }
      }

      .valuesContent {
        @include flex;
        @include alignItems(center);
        @include justifyContent(center);

        @include tab {
          @include flexDirection(col);
        }

        @include sp {
          @include flexDirection(col);
        }
      }

      .valuesImage {
        position: relative;
        margin-left: -12%;

        @include sp {
          margin-left: -12%;
          margin-right: -8%;
        }

        &::before {
          content: '';
          background: url('/images/img_kid_02.png') no-repeat;
          background-size: contain;
          width: 88px;
          height: 117px;
          position: absolute;
          bottom: -60px;
          right: 50px;
          z-index: 1;

          @include tab {
            display: none;
          }

          @include sp {
            display: none;
          }
        }

        &::after {
          content: '';
          background: url('/images/img_kid_01.png') no-repeat;
          background-size: contain;
          width: 45px;
          height: 168px;
          position: absolute;
          bottom: -160px;
          right: -30px;
          z-index: 1;

          @include tab {
            display: none;
          }

          @include sp {
            display: none;
          }
        }
      }

      .valuesText {
        margin-left: 30px;
        padding-top: 100px;
        padding-right: 1.5%;

        @include tab {
          margin-top: 40px;
        }

        @include sp {
          padding: 0 4%;
          margin-top: 30px;
          margin-left: 0;
        }

        h2 {
          margin-bottom: 20px;
          text-align: left;

          @include sp {
            width: 85%;
            margin: 0px auto 10px;
          }
        }

        p {
          text-align: center;
          font-size: 2.2rem;
          line-height: 52px;
          margin-bottom: 50px;

          @include sp {
            font-size: 1.5rem;
            line-height: 34px;
            margin-bottom: 40px;
          }

          span {
            font-weight: 700;
            color: #ee3ea0;
          }
        }
      }
    }

    .search {
      padding-top: 110px;
      position: relative;

      @include sp {
        padding-top: 80px;
      }

      &::before {
        content: '';
        background: url('/images/img_kid_03.png') no-repeat;
        background-size: contain;
        width: 287px;
        height: 133px;
        position: absolute;
        right: 50px;
        top: 200px;

        @include tab {
          display: none;
        }

        @include sp {
          display: none;
        }
      }

      .bTitleTextImage {
        @include pc {
          height: 106px;
        }
      }

      .container {
        max-width: 845px;

        @include sp {
          max-width: 100%;
        }
      }

      /* 「都道府県から探す」から流用・微調整[s] */
      .searchAccordionitem {
        &:not(:last-child) {
          margin-bottom: 50px;
          @include sp {
            margin-bottom: 40px;
          }
        }
      }

      .searchAccordionType {
        color: $orange;
        font-size: 1.8rem;
        font-weight: $bold;
        @include sp {
          font-size: 1.6rem;
        }
      }

      .searchAccordionLink {
        display: flex;
        gap: 15px 35px;
        margin-top: 15px;
        flex-wrap: wrap;
        @include sp {
          row-gap: 10px;
        }
        
        a {
          color: #323232;
          font-size: 1.8rem;
          text-decoration: none;
          transition: all 0.3s ;
          border-bottom: 1px solid rgba($color: $orange, $alpha: 0);
          @include sp {
            font-size: 1.6rem;
          }

          &:hover {
            color: $orange;
            border-bottom: 1px solid rgba($color: $orange, $alpha: 1);
          }
        }
      }

      .acoTabItem {
        padding: 60px 0 0;
        position: relative;
        @include sp {
          padding: 35px 0 0;
        }
      }
      /* 「都道府県から探す」から流用・微調整[e] */

      .acoTabBtn {
        display: flex;
        height: 60px;
        color: $orange;
        padding: 0 40px;
        margin-top: 40px;
        font-size: 1.8rem;
        font-weight: $bold;
        border-radius: 30px;
        align-items: center;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);

        @include sp {
          padding: 0 20px;
          height: 50px;
          font-size: 1.5rem;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
        }

        &::before,
        &::after {
          right: 40px;
          background-color: #323232;

          @include sp {
            right: 30px;
          }
        }
      }

      .searchButtons {
        @include flex;
        @include justifyContent(sb);
        gap: 0 30px;

        @include sp {
          @include flexWrap(wrap);
          @include justifyContent(center);
          gap: 15px;
        }
      }

      .searchButton {
        width: 400px;
        height: 97px;
        @include flex;
        @include justifyContent(sb);
        border-radius: 110px;
        border: 1px solid #f39700;
        background: #fff;
        overflow: hidden;
        text-decoration: none;

        @include sp {
          width: 100%;
          max-width: 400px;
          height: 74px;
        }

        .searchBtnTitle {
          width: 190px;
          @include flex;
          @include alignItems(center);
          @include justifyContent(center);
          background: #fff;
          position: relative;
          z-index: 1;
          margin-right: -10px;
          color: #f39700;
          font-size: 1.8rem;
          font-weight: 700;
        }

        .searchBtnImage {
          width: 53%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .voice {
      padding-top: 140px;
      margin-bottom: -70px;
      position: relative;
      z-index: 3;

      @include tab {
        margin-bottom: 0;
      }

      @include sp {
        padding-top: 70px;
        margin-bottom: 0;
      }

      .bTitle {
        color: #323232;
      }

      .container {
        max-width: 1070px;
        padding: 0;

        @include sp {
          padding: 0 5.3%;
        }
      }

      .voiceText {
        margin: 50px auto 0;
        display: block;
        text-align: center;

        @include sp {
          margin: 30px auto 0;
          padding: 0 3%;
        }
      }

      .voiceList {
        @include flex;
        @include flexWrap(wrap);
        @include justifyContent(sb);

        @include tab {
          @include justifyContent(center);
          gap: 20px;
          width: 500px;
          margin: 0 auto;
        }

        @include sp {
          @include justifyContent(center);
          gap: 20px;
        }
      }

      .voiceItem {
        width: 210px;
        height: 215px;
        @include flex;
        @include flexDirection(col);
        @include alignItems(center);
        @include justifyContent(center);
        text-align: center;
        background: url('/images/img_voice_bg_01.png') no-repeat;
        background-size: 100%;

        @include tab {
          width: 43%;
          height: 222px;
          margin: 0 2%;
        }

        @include sp {
          width: 43%;
          height: 39vw;
          margin: 0 2%;
        }

        &.voiceItemMain {
          width: 475px;
          height: 485px;

          @include tab {
            width: 90%;
            max-width: 300px;
            max-height: 310px;
            height: 83vw;
          }

          @include sp {
            width: 90%;
            max-width: 300px;
            max-height: 310px;
            height: 83vw;
          }

          .voiceMainText {
            @include tab {
              width: 112px;
            }

            @include sp {
              width: 112px;
            }
          }

          .voiceMainNumber {
            @include tab {
              width: 166px;
            }

            @include sp {
              width: 166px;
            }
          }

          .voiceMainIcon {
            @include tab {
              width: 185px;
            }

            @include sp {
              width: 185px;
            }
          }
        }

        p {
          margin-bottom: 0;
          font-size: 1.6rem;
          font-family: $font-zenmaru;
          font-weight: 700;
          letter-spacing: 0.05em;
          line-height: 32px;

          @include sp {
            font-size: 1.2rem;
            line-height: 20px;
            letter-spacing: 0;
          }
        }

        &:nth-of-type(1) {
          margin: 0 30px;

          @include tab {
            order: 2;
            margin: 0 2%;
          }

          @include sp {
            order: 2;
            margin: 0 2%;
          }
        }

        &:nth-of-type(2) {
          background: url('/images/img_voice_bg_05.png') no-repeat;
          background-size: 100%;
          margin-top: 50px;
          gap: 30px 0;

          @include tab {
            margin-top: 0;
            order: 1;
            gap: 10px 0;
            margin: 0 50px;
          }

          @include sp {
            margin-top: 0;
            order: 1;
            gap: 10px 0;
          }
        }

        &:nth-of-type(3) {
          background: url('/images/img_voice_bg_02.png') no-repeat;
          background-size: 100%;
          margin: 0 30px;

          @include tab {
            order: 4;
            margin: 0 2%;
          }

          @include sp {
            order: 4;
            margin: 0 2%;
          }
        }

        &:nth-of-type(4) {
          background: url('/images/img_voice_bg_03.png') no-repeat;
          background-size: 100%;
          margin-top: -270px;

          @include tab {
            margin-top: 0;
            order: 3;
          }

          @include sp {
            margin-top: 0;
            order: 3;
          }
        }

        &:nth-of-type(5) {
          width: 235px;
          height: 240px;
          background: url('/images/img_voice_bg_04.png') no-repeat;
          background-size: 100%;
          margin-top: -300px;

          @include tab {
            margin-top: 0;
            order: 5;
            width: 43%;
            height: 222px;
          }

          @include sp {
            margin-top: 0;
            order: 5;
            width: 43%;
            height: 39vw;
          }
        }
      }
    }

    .partition {
      margin-top: 50px;
      width: 100%;
      position: relative;
      overflow: hidden;
      @include tab{
        margin-top: 0;
        height: 68vw;
      }

      @include sp {
        margin-top: 0;
        height: 68vw;
      }

      img {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        object-fit: cover;
        object-position: center;
        min-width: 100%;
        transition: transform 0.2s ease-out;
      }

      .simpleParallax{
        position: relative;
        max-height: 940px;
        &::before {
          content: '';
          background: url('/images/img_partition_decor_01.png') no-repeat bottom center;
          background-size: 100%;
          width: 100%;
          height: 220px;
          position: absolute;
          z-index: 1;
          top: -1px;
          left: 0;
          right: 0;
          margin: 0 auto;
          @include tab{
            top: 0;
            height: calc(24vw - 10px);
          }
          @include sp {
            top: 0;
            height: calc(24vw - 10px);
          }
        }
  
        &::after {
          content: '';
          background: url('/images/img_partition_decor_02.png') no-repeat top center;
          background-size: 100%;
          width: 100%;
          height: 140px;
          position: absolute;
          z-index: 0;
          bottom: -1px;
          left: 0;
          right: 0;
          margin: 0 auto;
          @include tab{
            height: 13.3vw;
            bottom: -10px;
          }
          @include sp{
            height: 13.3vw;
            bottom: -10px;
          }
        }
      }

      
    }

    .recruit {
      padding-top: 56px;

      @include sp {
        padding-top: 40px;
      }

      .bTitle {
        color: #69318e;
      }
    }

    .corporate {
      padding-top: 120px;

      @include sp {
        padding-top: 32px;
      }

      .bTitle {
        color: #2ea7e0;
        margin-bottom: 5px;
      }

      .bTitleTextImage {
        height: 110px;

        @include sp {
          height: 70px;
        }
      }

      .topBlockImage {
        margin-top: 40px;
      }
    }

    .training {
      padding: 96px 0 140px;
      position: relative;

      @include sp {
        padding: 24px 0;
      }

      .bTitle {
        color: #ee3ea0;

        span {
          @include pc {
            margin-top: 5px;
          }
        }
      }

      .bTitleTextImage {
        height: 112px;

        @include sp {
          height: 70px;
        }
      }

      &::before {
        content: '';
        background: url('/images/img_kid_04.png');
        background-size: 100%;
        width: 407px;
        height: 146px;
        position: absolute;
        bottom: 50px;
        right: 100px;

        @include tab {
          display: none;
        }

        @include sp {
          display: none;
        }
      }
    }

    .recruit,
    .corporate,
    .training,
    .useful {
      .container {
        @media screen and (max-width: 1234px) and (min-width: 1025px) {
          padding: 0 3%;
        }
      }
    }

    .topBlockContent {
      width: 54%;

      @include tab {
        width: 100%;
      }

      @include sp {
        width: 100%;
      }
    }

    .topBlockWrap {
      padding: 35px 0;

      .lgBtn {
        margin-top: 25px;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid #e0e0e0;
      }

      &:last-of-type {
        padding-top: 0;
      }

      &.sp-mt30 {
        @include tab {
          margin-top: 30px;
        }

        @include sp {
          margin-top: 30px;
        }
      }
    }

    .topBlockText {
      margin: 0;
      font-size: 1.6rem;
      letter-spacing: 0.0005em;
      line-height: 32px;

      @include sp {
        line-height: 29px;
      }
    }

    .topBlockTitle {
      margin: 30px 0 20px;
      font-size: 1.8rem;
      font-weight: 700;
      letter-spacing: 0.05em;

      @include sp {
        font-size: 1.6rem;
        margin: 20px 0 10px;
      }
    }

    .topBlockImage {
      // margin: 0 50px 0 0px;
      width: 43%;

      @include tab {
        width: 85%;
        margin: 0 auto;
        max-width: 500px;
      }

      @include sp {
        width: 85%;
        margin: 0 auto;
      }

      img {
        width: 100%;
      }
    }

    .topBlock {
      @include flex;
      @include justifyContent(sb);
      @include alignItems(center);
      width: 100%;

      @include tab {
        @include flexDirection(col);
        gap: 30px;
      }

      @include sp {
        @include flexDirection(col);
        gap: 30px;
      }

      &.topBlockReverse {
        @include flexDirection(rowR);

        @include tab {
          @include flexDirection(col);
        }

        @include sp {
          @include flexDirection(col);
        }

        .topBlockImage {
          @include tab {
            margin-top: 40px;
          }

          @include sp {
            margin-top: 40px;
          }
        }
      }

      &.topBlockStart {
        @include alignItems(center);
      }
    }

    .useful {
      width: 100%;
      padding: 76px 0 70px;
      position: relative;

      @include sp {
        padding: 50px 0 40px;
      }

      &::before {
        content: '';
        background: url('/images/img_useful.jpg') no-repeat;
        background-size: cover;
        background-position: bottom;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        // opacity: 0.3;
        z-index: -1;
      }

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(#797979, 0.2);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: -1;
      }

      .bTitle {
        color: #fff;
        line-height: 1.6;
        margin-bottom: 40px;

        @include sp {
          margin-bottom: 30px;
          line-height: 1.3;
        }

        span {
          color: #fff;

          @include pc {
            margin-top: 58px;
          }
        }
      }

      .bTitleTextImage {
        @include pc {
          height: 105px;
        }
      }

      .lgBtnWhite a {
        background: transparent;
        border-color: #fff;
        color: #fff;

        @include tab {
          width: 100%;
          margin-top: 20px;
        }

        @include sp {
          width: 100%;
          margin-top: 20px;
        }

        &::after {
          background: url(/common/images/icon_arrowWhite.svg) no-repeat;
        }

        &:hover {
          background: #fff;
          color: #323232;

          &::after {
            background: url(/common/images/icon_arrowBlack.svg) no-repeat;
          }
        }
      }

      .topBlockText {
        color: #fff;
        width: 55%;

        @include tab {
          width: 100%;
        }

        @include sp {
          width: 100%;
        }
      }

      .m-center {
        @include tab {
          width: 100%;
        }

        @include sp {
          width: 100%;
        }
      }
    }

    .news {
      padding-top: 134px;

      @include sp {
        padding-top: 70px;
      }

      .bTitle {
        color: #e6002d;
      }

      .container {
        @media screen and (max-width: 1234px) and (min-width: 1025px) {
          padding: 0 3%;
        }
      }

      .smlBtn a {
        font-size: 1.6rem;
      }

      .newsContent {
        @include flex;
        @include justifyContent(sb);

        @include sp {
          @include flexDirection(col);
        }
      }

      .newsCategories {
        width: 35%;
        padding-right: 40px;
        @include flex;
        @include flexDirection(col);
        gap: 20px 0;
        position: relative;
        flex-shrink: 0;

        @include sp {
          width: 100%;
          @include flexDirection(row);
          @include flexWrap(wrap);
          @include justifyContent(center);
          gap: 10px;
          padding-right: 0;
          padding-bottom: 30px;
        }

        &::after {
          content: '';
          background: url('/images/img_kid_05.png') no-repeat;
          background-size: contain;
          width: 50px;
          height: 158px;
          position: absolute;
          bottom: 0;
          right: 30px;

          @include tab {
            display: none;
          }

          @include sp {
            display: none;
          }
        }
      }

      .newsCat {
        display: block;
        text-align: center;
        color: #323232;
        font-size: 1.6rem;
        font-weight: 700;
        text-decoration: none;
        padding: 15px 0;
        // transition: all 0.3s ease;
        border-radius: 40px;

        @include sp {
          padding: 2px 10px;
        }

        &:hover {
          background: #ffeef1;
        }

        &.active {
          color: #e6002d;
          background: #ffeef1;
        }
      }

      .newsList {
        width: 100%;
        border-left: 2px solid #e6002d;
        padding-left: 40px;
        padding-bottom: 15px;

        @include sp {
          padding: 0;
          border-left: 0;
          border-top: 2px solid #e6002d;
        }
      }

      .newsItem {
        a {
          padding: 20px 0;
          display: block;
          text-decoration: none;
          color: #323232;
          border-bottom: 1px solid #e0e0e0;
          opacity: 1;
          transition: opacity 0.3s;

          &:hover {
            @include pc {
              opacity: 0.6;
            }
          }
        }
      }

      .newsLabel {
        margin-bottom: 15px;
        @include flex;
        @include alignItems(center);

        .date {
          font-size: 1.4rem;

          @include sp {
            font-size: 1.6rem;
          }
        }

        .cat {
          border: 1px solid #e0e0e0;
          padding: 5px 15px;
          border-radius: 30px;
          margin-left: 25px;

          @include sp {
            padding: 2px 15px;
          }

          &.red {
            color: #e6002d;
          }
        }
      }

      .newsTitle {
        margin-bottom: 0;
      }
    }

    .contact {
      padding: 120px 0;

      @include sp {
        padding: 70px 0;
      }

      .bTitle {
        color: #8fc31f;
      }

      .container {
        max-width: 930px;

        @include sp {
          max-width: 100%;
        }
      }

      .lgBtnGreen a {
        width: 450px;

        @include sp {
          width: 100%;
          font-size: 1.4rem;
        }
      }

      .contactText {
        margin-bottom: 30px;

        @include sp {
          margin-bottom: 20px;
        }
      }

      .contactContent {
        @include flex;
        @include justifyContent(sb);
        @include alignItems(center);

        @include tab {
          @include flexDirection(col);
        }

        @include sp {
          @include flexDirection(col);
        }
      }

      .contactTel {
        width: 447px;
        height: 170px;
        border: 1px solid #cdcdcd;
        text-decoration: none;
        color: #323232;
        @include flex;
        @include justifyContent(center);
        @include flexDirection(col);

        @include sp {
          width: 100%;
          height: 138px;
        }

        .contactTelTitle {
          font-size: 1.8rem;
          font-weight: 700;
          text-align: center;

          @include sp {
            font-size: 1.4rem;
          }
        }

        .contactTelNote {
          text-align: right;
          margin-right: 90px;
          display: inline-block;
          font-size: 1.2rem;
          margin-bottom: -3px;

          @include sp {
            margin-right: 50px;
          }
        }

        .contactTelNum {
          text-align: center;
          font-size: 4.8rem;
          font-weight: 700;
          line-height: 1;

          @include sp {
            font-size: 4rem;
          }

          &::before {
            content: '';
            background: url('/images/icon_phone.svg') no-repeat;
            background-size: 100%;
            width: 23px;
            height: 37px;
            display: inline-block;
            margin-right: 10px;
          }
        }

        .contactTelText {
          text-align: center;
          font-size: 1.2rem;
          margin-top: 10px;
        }
      }

      .contactButtons {
        @include flex;
        @include flexDirection(col);
        gap: 30px 0;

        @include tab {
          margin-top: 30px;
          gap: 20px 0;
          width: 100%;
        }

        @include sp {
          margin-top: 30px;
          gap: 20px 0;
          width: 100%;
        }
      }
    }
  }
}